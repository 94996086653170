import { useQuery, gql } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import i18next, { changeLanguage } from 'i18next';
import Gleap from 'gleap';
import { useSnackbar } from 'notistack';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

import { useCustomLazyQuery } from './graphql';

// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';

const GET_LOCAL_CLIENT_ID = gql`
  query LocalClientId {
    clientId @client
  }
`;

const GET_MYSELF = gql`
  query Myself($activeClientId: ID!) {
    clientId @client @export(as: "activeClientId")
    myselfMultiAccount(activeClientId: $activeClientId) {
      id
      isAdmin
      currentClient {
        id
        accessManualCosts
        availableConnectors {
          id
          name
        }
        availableViews {
          id
          name
          filters {
            type
            ids
          }
          author
        }
      }
      table {
        id
        columnsToHide
      }
      graphOptions {
        id
        indicatorsToShow
      }
      languageCode
      clientsAccess {
        clientId
        clientName
        group
        selectedView
        productsAccess {
          analytics
          affiliates
          brandSafety
        }
      }
      blocked
    }
  }
`;

export const useMyself = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIdentity } = useTrackingCode();

  const { data: dataLocalActiveClientId, client } = useQuery(
    GET_LOCAL_CLIENT_ID
  );

  const { loadLazy: loadMyself, data } = useCustomLazyQuery(GET_MYSELF, {
    fetchPolicy: 'network-only',
    showLoader: false,
    errorHandling: {
      signOutOnError: true
    }
  });

  // Change app language dynamically
  useEffect(() => {
    if (
      data?.myselfMultiAccount?.languageCode &&
      i18next.resolvedLanguage !== data.myselfMultiAccount.languageCode
    ) {
      if (process.env.NODE_ENV === 'production') {
        Gleap.setLanguage(data.myselfMultiAccount.languageCode);
      }

      changeLanguage(data.myselfMultiAccount.languageCode);
    }
  }, [data?.myselfMultiAccount?.languageCode]);

  useEffect(() => {
    if (data && process.env.NODE_ENV === 'production') {
      const currentClient = data.myselfMultiAccount.clientsAccess.find(
        ({ clientId }) => clientId === data.myselfMultiAccount.currentClient.id
      );

      Gleap.identify(data.myselfMultiAccount.id, {
        name: data.myselfMultiAccount.id,
        email: data.myselfMultiAccount.id,
        customData: {
          clientName: currentClient?.clientName,
          clientRole: currentClient?.group,
          'Marketing access': currentClient?.productsAccess.analytics
            ? 'true'
            : 'false',
          'Affiliates access': currentClient?.productsAccess.affiliates
            ? 'true'
            : 'false',
          'Brand-Safety access': currentClient?.productsAccess.brandSafety
            ? 'true'
            : 'false'
        }
      });

      posthog.identify(window.btoa(data.myselfMultiAccount.id), {
        email: data.myselfMultiAccount.id,
        clientId: data.myselfMultiAccount.currentClient.id,
        clientName: currentClient?.clientName,
        clientRole: currentClient?.group
      });

      if (currentClient) {
        posthog.group('client', data.myselfMultiAccount.currentClient.id, {
          id: data.myselfMultiAccount.currentClient.id,
          name: data.myselfMultiAccount.currentClient.name
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isFromThankYouAnalyticsTeam = useCallback(
    () => !!data?.myselfMultiAccount?.id?.endsWith('thank-you.io'),
    [data]
  );

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();

      if (localStorage.getItem('ty_datepicker_compare_from')) {
        localStorage.removeItem('ty_datepicker_compare_from');
      }

      if (localStorage.getItem('ty_datepicker_compare_to')) {
        localStorage.removeItem('ty_datepicker_compare_to');
      }

      if (localStorage.getItem('ty_datepicker_from')) {
        localStorage.removeItem('ty_datepicker_from');
      }

      if (localStorage.getItem('ty_datepicker_to')) {
        localStorage.removeItem('ty_datepicker_to');
      }

      window.location.href = '/';
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (client) {
      Auth?.currentSession()?.then(res => {
        let params = new URLSearchParams(window.location.search);

        let clientIdToSet = undefined;

        if (
          !res.getIdToken()?.payload?.organizations ||
          res.getIdToken()?.payload?.organizations === 'global'
        ) {
          Auth.signOut();
        }

        if (
          params.has('activeClientId') &&
          res
            .getIdToken()
            .payload.organizations.split('|')
            .includes(params.get('activeClientId'))
        ) {
          clientIdToSet = params.get('activeClientId');
        } else {
          clientIdToSet =
            (localStorage.getItem('ty_client_id') !== 'null' &&
              localStorage.getItem('ty_client_id') !== 'global' &&
              res
                .getIdToken()
                .payload.organizations.split('|')
                .includes(localStorage.getItem('ty_client_id')) &&
              localStorage.getItem('ty_client_id')) ||
            res
              .getIdToken()
              .payload.organizations.split('|')
              .filter(id => id !== 'global')[0];
        }

        client.writeQuery({
          query: GET_LOCAL_CLIENT_ID,
          data: {
            clientId: clientIdToSet
          }
        });

        if (
          res
            .getIdToken()
            .payload.organizations.split('|')
            .includes(dataLocalActiveClientId?.clientId)
        ) {
          try {
            loadMyself();
          } catch (err) {
            console.log(err);
          }
        }
      });
    }
  }, [dataLocalActiveClientId?.clientId, client, loadMyself]);

  return {
    myself: data?.myselfMultiAccount || null,
    reloadMyself: loadMyself,
    isFromThankYouAnalyticsTeam: isFromThankYouAnalyticsTeam(),
    signOut
  };
};
